$primary: #3c90b5;
$body-bg: #f4f4f4;
$font-size-base: 1rem;

$h7-font-size: $font-size-base * 0.85;
$font-sizes: (
  7: $h7-font-size,
);

@import 'bootstrap';

.btn-primary,
.btn-primary:hover {
  color: #ffffff;
}

.vh-94 {
  height: 94vh;
}
.zi-1 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}

.spinner-container {
  z-index: 10;
  position: absolute;
  background-color: white;
  opacity: 0.8;
  width: 98%;
  height: 82%;
  z-index: 10;
}

.menu-dialog-xl > .modal-dialog {
  max-width: 90% !important;
}

.table-padded tr {
  height: 1.2rem;
}

.table-padded tr td:first-child {
  width: 10rem;
}

.admin-table tr {
  height: 3rem;
}

.loader-box {
  max-width: 20rem;
  margin: auto;
  padding: 2.5rem;
  color: white;
  background: #000000b5;
  border-radius: 0.5rem;
}
