.responsive_table {
  position: relative;
  font-size: 0.9rem !important;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
}
/*
@media screen and (max-width: 992px), screen and (max-height: 700px) {
  .responsive_table {
    box-shadow: none;
  }
}
*/

.header {
  color: white;
  font-weight: bold;
}
.header > div {
  padding: 0.375rem 0.3rem;
}
.header > div:global.sortable {
  cursor: pointer;
}

.footer {
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-top: solid 0.15rem var(--bs-primary);
}

.row > * {
  padding: 0.375rem 0.3rem;
}

.last_row {
  top: calc(50% - 1rem);
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.scroll_area {
  border-left: solid 0.1rem var(--bs-light);
  border-right: solid 0.1rem var(--bs-light);
}

.norecords {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  z-index: 400;
}
.norecords div {
  z-index: 500;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 400;
}
.mask div {
  z-index: 500;
}
@media screen and (max-width: 992px), screen and (max-height: 700px) {
  .mask div {
    left: calc(50% - 9rem);
    position: fixed;
    width: 18rem;
    text-align: center;
    padding: 1rem;
    z-index: 500;
    bottom: 7rem;
  }
}
